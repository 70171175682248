
<header>
  <div class="menu">

  </div>
  <div class="intro">
    <div class="row">
      <div class="col-8 offset-2">
        <a href="" title="home"><img src="/assets/images/Clee-logo-name-white.png" alt="Clee logo"
            width="200px" /></a>
      </div>
    </div>
  </div>


  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top d-lg-none">
    <div class="container">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link nav-title" tabindex="-1" aria-disabled="true">Aan de slag</a>
          </li>
          <li class="nav-item"><a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a></li>
          <li class="nav-item"><a class="nav-link" href="nl/documentation/aandeslag#step0">Algemeen</a></li>
          <li class="nav-item"><a class="nav-link" href="nl/documentation/aandeslag#step1">Je domein whitelisten in RealSmart</a></li>
          <li class="nav-item"><a class="nav-link" href="nl/documentation/aandeslag#step2">JSON-LD</a></li>
          <li class="nav-item"><a class="nav-link" href="nl/documentation/aandeslag#step3">Onze javascript integreren</a></li>
          <li class="nav-item"><a class="nav-link" href="nl/documentation/aandeslag#step4">Bepaal de locatie van je widget</a></li>
          <li class="nav-item"><a class="nav-link" href="nl/documentation/aandeslag#step5">Activeer de widget</a></li>
          <li class="nav-item">
            <a class="nav-link nav-title" tabindex="-1" aria-disabled="true">Geavanceerd</a>
          </li>
          <li class="nav-item"><a class="nav-link" (click)="scrollTo('step6')" [routerLink]="">De look & feel aanpassen</a></li>
        </ul>
      </div>
    </div>
  </nav>


</header>

<div class="container">
  <div class="row">
    <div class="col-3 d-none d-lg-block">
      <div class="menubox">
        <h1>Aan de slag</h1>

        <ul class="topics">
          <li><a (click)="scrollTo('step0')" [routerLink]=""><i class="fas fa-file-alt"></i> Algemeen</a></li>
          <li><a (click)="scrollTo('step1')" [routerLink]=""><i class="fas fa-file-alt"></i> Je domein whitelisten in RealSmart</a></li>
          <li><a (click)="scrollTo('step2')" [routerLink]=""><i class="fas fa-file-alt"></i> JSON-LD</a></li>
          <li><a (click)="scrollTo('step3')" [routerLink]=""><i class="fas fa-file-alt"></i> Onze javascript integreren</a></li>
          <li><a (click)="scrollTo('step4')" [routerLink]=""><i class="fas fa-file-alt"></i> Bepaal de locatie van je widget</a></li>
          <li><a (click)="scrollTo('step5')" [routerLink]=""><i class="fas fa-file-alt"></i> Activeer de widget</a></li>
        </ul>

        <br />

        <h1>Geavanceerd</h1>

        <ul class="topics">
          <li><a (click)="scrollTo('step6')" [routerLink]=""><i class="fas fa-file-alt"></i> De look & feel aanpassen</a></li>
        </ul>
      </div>


    </div>

    <div class="col-12 col-lg-9 move-up">


      <div class="card menu-card">
        <div class="card-body">

          <h1>Aan de slag</h1>

          <a id="step0"></a>
          <h2>Algemeen</h2>

          <p>Naast een manier om kandidaat te stellen voor de bezoeker wordt de Clee widget ook gebruikt om de nodige
            pand informatie op te halen. Deze informatie wordt enerzijds gebruikt voor de verdere opvolging van deze
            kandidaturen binnen RealSmart te faciliteren, en anderzijds om het pand te visualiseren binnen de Clee
            omgeving voor de kandidaat.</p>

          <p>Integreer de Clee widget in je website via onderstaande eenvoudige stappen. Plaats de widget <b>enkel op
              detailpagina's van actief huuraanbod</b> en enkel op <b>op Vlaams grondgebied</b>. De ondersteuning voor
            Brussel en Wallonië volgt later.</p>

          <hr />


          <a id="step1"></a>
          <h2>Je domein whitelisten in RealSmart</h2>

          <p>De widget moet eerst door de makelaar (of eender wie met de nodige toegangsrechten) geactiveerd worden voor
            het domein waarop je deze wenst te publiceren. Dit kan je in RealSmart als volgt doen:</p>

          <ol>
            <li>Inloggen in RealSmart</li>
            <li>Klikken op icoon 'instellingen'</li>
            <li>Klikken op 'Clee instellingen' in het linkermenu</li>
            <li>Voeg de gewenste domeinen toe aan de lijst. Vergeet ook niet de gewenste subdomeinen toe te voegen. Voeg
              zeker ook http(s) toe.</li>
          </ol>

          <ul class="topics">
            <li><a (click)="scrollTo('step6')" [routerLink]=""><i class="fas fa-file-alt"></i> De look & feel aanpassen</a></li>
          </ul>

          <hr />

          <a id="step2"></a>
          <h2>JSON-LD</h2>

          <p>Via onderstaande JSON-LD (voorbeeld) kan de Clee widget de nodige basis informatie van het actieve pand
            ophalen.
            </p>

            <p>Op dit moment is de ondersteuning van websites beperkt tot Statische en Server Side Rendered websites.
Dat wil zeggen dat wij ons beperken tot de inhoud die onmiddellijk beschikbaar is (i.e. wat je kan zien bij het oproepen van "view-source:url").</p>

          <pre><code>// HET OBJECT
&lt;script type="application/ld+json"&gt;
 {{house | json}}
&lt;/script&gt;

// PRIJS & LOCATIE
&lt;script type="application/ld+json"&gt;
{{ retnAction | json }}
&lt;/script&gt;</code></pre>


          <table class="table">
            <thead>
              <th class="labelcolumn">Variabele</th>
              <th>Omschrijving</th>
            </thead>
            <tbody>
              <tr>
                <td colspan="2"><b>Het object</b></td>
              </tr>
              <tr>
                <td>
                  @type
                </td>
                <td>
                  House, Apartment
                </td>
              </tr>
              <tr>
                <td>
                  name
                </td>
                <td>
                  De commerciële naam van het object
                </td>
              </tr>
              <tr>
                <td>
                  numberOfRooms
                </td>
                <td>
                  Het aantal ruimtes
                </td>
              </tr>
              <tr>
                <td>
                  numberOfBedrooms
                </td>
                <td>
                  Het aantal slaapkamers
                </td>
              </tr>
              <tr>
                <td>
                  petsAllowed
                </td>
                <td>
                  (optioneel) O: Geen huisdieren toegelaten, 1: Huisdieren toegelaten
                </td>
              </tr>
              <tr>
                <td>
                  floorSize
                </td>
                <td>
                  De oppervlakte in m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <td>
                  url
                </td>
                <td>
                  De link naar het detail van het pand (op je website)
                </td>
              </tr>
              <tr>
                <td>
                  identifier
                </td>
                <td>
                  De unieke identifier van het pand. Igv Skarabee raden we het property_id aan.<br />
                  Het is vereist dat dit uniek is voor iedere nieuwe verhuurtransactie.
                </td>
              </tr>
              <tr>
                <td>
                  image
                </td>
                <td>
                  Link naar de foto.
                </td>
              </tr>
              <tr>
                <td>
                  description
                </td>
                <td>
                  Commerciële omschrijving van het pand.
                </td>
              </tr>
              <tr>
                <td>
                  accommodationCategory
                </td>
                <td>
                   (optioneel) subcategorie van het pand: Studio is hier wel van belang
                </td>
              </tr>
              <tr>
                <td>
                  reference
                </td>
                <td>
                  interne referentie
                </td>
              </tr>
              <tr>
                <td colspan="2"><b>Prijs</b> (priceSpecification)</td>
              </tr>
              <tr>
                <td>
                  price
                </td>
                <td>
                  De prijs per maand.
                </td>
              </tr>
              <tr>
                <td>
                  monthlyExtraCosts
                </td>
                <td>
                  Maandelijkse kosten (garage / gemeenschappelijke kosten)
                </td>
              </tr>
              <tr>
                <td>
                  priceCurrency
                </td>
                <td>
                  Altijd EUR
                </td>
              </tr>
              <tr>
                <td colspan="2"><b>Locatie</b> (location)</td>
              </tr>
              <tr>
                <td>
                  streetAddress
                </td>
                <td>
                  Straat, nr en bus
                </td>
              </tr>
              <tr>
                <td>
                  addressLocality
                </td>
                <td>
                  Gemeente
                </td>
              </tr>
              <tr>
                <td>
                  postalCode
                </td>
                <td>
                  Postcode
                </td>
              </tr>
              <tr>
                <td>
                  addressCountry
                </td>
                <td>
                  Land (BE)
                </td>
              </tr>
            </tbody>
          </table>

          <hr />

          <a id="step3"></a>
          <h2>Onze javascript integreren</h2>

          <p>Kopieer en plak onderstaande &lt;script> tag binnen de &lthead> sectie van je webpagina.</p>

          <pre><code>&lt;script src="https://api.clee.be/Clee.js">&lt;/script></code></pre>


          <hr />

          <a id="step4"></a>
          <h2>Bepaal de locatie van je widget</h2>

          <p>Plaats onderstaande &lt;div> op de plaats waar je wenst dat de widget verschijnt. De widget neemt
            automatisch de volledige breedte aan van het 'block' element waarin deze geplaatst wordt. De widget is dus
            ook responsive.</p>

          <pre><code>&lt;div id="CleeWidget">&lt;/div></code></pre>


          <hr />

          <a id="step5"></a>
          <h2>Activeer de widget</h2>

          <p>Plaats onderstaande &lt;script> onderaan de &lt;body> van je website.</p>

          <pre><code>&lt;script>
            initClee(<i>KBO nr</i>, <i>Pand identifier</i>, <i>Taal</i>, <i>profielinfo tonen</i>, <i>kandidaatstellen knop tonen</i>, <i>style</i>, <i>widgetId</i>);
&lt;/script></code></pre>

          <table class="table">
            <thead>
              <th class="labelcolumn">Variabele</th>
              <th>Omschrijving</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>KBO nr</b>
                </td>
                <td>
                  Het KBO nr van je onderneming: <s>BE</s>0XXXXXXXXX (dit is het kbo nummer zonder BE)
                </td>
              </tr>
              <tr>
                <td>
                  <b>Pand identifier</b>
                </td>
                <td>
                  Unieke identifier van het pand. Wanneer je gebruikt maakt van Skarabee raden we hier de 'Property ID'
                  aan.
                </td>
              </tr>
              <tr>
                <td>
                  <b>Taal</b>
                </td>
                <td>
                  De taal waarin de widget moet verschijnen. Volgende opties worden ondersteund: 'nl', 'en', 'fr', 'de'
                </td>
              </tr>
              <tr>
                <td>
                  <b>profielinfo tonen</b>
                </td>
                <td>
                  true of false: bepaalend of al dan niet een profiel kan worden opgesteld om scorebepaling te voorzien ('Kan ik dit huren?')
                </td>
              </tr>
              <tr>
                <td>
                  <b>kandidaatstellen knop tonen</b>
                </td>
                <td>
                  true of false: bepaalend of al dan niet een knop met link naar kandidaatstelling getoont wordt ('Ik stel me kandidaat')
                </td>
              </tr>
              <tr>
                <td>
                  <b>style</b>
                </td>
                <td>
                  Json object van de aangepaste styling
                </td>
              </tr>
              <tr>
                <td>
                  <b>widgetId</b>
                </td>
                <td>
                  Optioneel: wanneer er afgeweken word van div id CleeWidget kan hier het ID worden meegegeven. Bvb wanneer widget meerdere keren per pagina getoond wordt,
                  met name wanneer de 'Kan ik dit huren?' widget en de 'Ik stel me kandidaat' widget op een verschillende plaats afgebeeld wordt.
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="card menu-card">
        <div class="card-body">
          <h1>Geavanceerd</h1>

          <a id="step6"></a>
          <h2>De stijl configureren</h2>

          <p>Je kan het design van de widget laten aansluiten aan dat van je website. De instellingen hiervan kunnen we
            meegeven in JSON formaat:</p>

          <pre><code>{{buttonStyle | json}}</code></pre>

          <table class="table">
            <thead>
              <th class="labelcolumn">Variabele</th>
              <th>Omschrijving</th>
            </thead>
            <tbody>
              <tr>
                <td colspan="2" style="background: #f3f3f3;"><b>Button</b></td>
              </tr>
              <tr>
                <td>
                  <b>align</b>
                </td>
                <td>
                  De uitlijning van de tekst op de knop (left, right, center).
                </td>
              </tr>
              <tr>
                <td>
                  <b>font</b>
                </td>
                <td>
                  Hier kan je een standaard webfont ingeven.
                </td>
              </tr>
              <tr>
                <td>
                  <b>bkgrnd</b>
                </td>
                <td>
                  Het kleur van de knop.
                </td>
              </tr>
              <tr>
                <td>
                  <b>hbkgrnd</b>
                </td>
                <td>
                  Het kleur van de knop wanneer je met de muis erover beweegt.
                </td>
              </tr>
              <tr>
                <td>
                  <b>bordr</b>
                </td>
                <td>
                  Afronding van de hoeken van de knop (5px, ...)
                </td>
              </tr>
              <tr>
                <td>
                  <b>bord</b>
                </td>
                <td>
                  De rand van de knop.<br />
                  Bvb: 1px solid #ddd
                </td>
              </tr>
              <tr>
                <td>
                  <b>hbord</b>
                </td>
                <td>
                  De rand van de knop wanneer je met de muis erover beweegt .<br />
                  Bvb: 1px solid #ddd
                </td>
              </tr>
              <tr>
                <td>
                  <b>color</b>
                </td>
                <td>
                  Het kleur van de tekst<br />
                  Bvb: FFFFFF
                </td>
              </tr>
              <tr>
                <td>
                  <b>deco</b>
                </td>
                <td>
                  Text opmaak (none, underline)
                </td>
              </tr>
              <tr>
                <td>
                  <b>pad</b>
                </td>
                <td>
                  De padding ofwel ruimte t.o.v. de rand binnen de knop.
                </td>
              </tr>
              <tr>
                <td>
                  <b>disp</b>
                </td>
                <td>
                  Hoe de knop zich moet verhouden t.o.v. de leeslijn (block, inline, ...)
                </td>
              </tr>
              <tr>
                <td>
                  <b>textTransform</b>
                </td>
                <td>
                  Transformatie van de tekst op de knop (none, uppercase, lowercase, capitalize)
                </td>
              </tr>
              <tr>
                <td>
                  <b>width</b>
                </td>
                <td>
                  Breedte van de knop. (auto, 100%, 200px, ...)
                </td>
              </tr>
              <tr>
                <td>
                  <b>lineHeight</b>
                </td>
                <td>
                  De lijnhoogte van de tekst.
                </td>
              </tr>
              <tr>
                <td>
                  <b>fontSize</b>
                </td>
                <td>
                  De tekstgrootte. Kan uitgedrukt worden in px, em, ...
                </td>
              </tr>
              <tr>
                <td>
                  <b>fontWeight</b>
                </td>
                <td>
                  De dikte van de tekst. (normal, bold, 100, ...)
                </td>
              </tr>
              <tr>
                <td>
                  <b>boxShadow</b>
                </td>
                <td>
                  Hiermee kan je een schaduw toevoegen op de knop.<br />
                  bvb: rgba(0, 0, 0, 0.1) 0px 5px 25px 0px
                </td>
              </tr>
              <tr>
                <td>
                  <b>iconShow</b>
                </td>
                <td>
                  Toon het icoon op de knop of niet (0,1).
                </td>
              </tr>
              <tr>
                <td>
                  <b>iconColor</b>
                </td>
                <td>
                  De kleur van het icoon op de knop.
                </td>
              </tr>
              <tr>
                <td colspan="2" style="background: #f3f3f3;"><b>Body</b></td>
              </tr>
              <tr>
                <td>
                  <b>bkgrnd</b>
                </td>
                <td>
                  Het achtergrondkleur van de widget. (FFFFFF, ...)
                </td>
              </tr>
            </tbody>
          </table>

          <p>Deze JSON string kan je meegeven met de init functie als volgt:</p>

          <pre><code>&lt;script>
var styles = {{buttonStyle | json}}
initClee(<i>KBO nr</i>, <i>Pand identifier</i>, <i>Taal</i>, true, true, styles);
&lt;/script></code>
        </pre>
        </div>
      </div>

    </div>



    <div class="col-12">
      <div class="card menu-card">
        <div class="card-body">
          <div class="row">
            <div class="col-1 iconbox d-none d-md-block">
              <i class="fas fa-question"></i>
            </div>
            <div class="col-12 col-md-11">
              <h2>Hulp nodig?</h2>
              <p>Vragen of problemen met betrekking tot de integratie van de widget?</p>
              <a class="btn btn-primary" href="mailto:contact@clee.be">Contacteer ons</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<footer>
  <div class="container">
    <img src="/assets/images/oris-logo.png" height="50">
    <img src="/assets/images/cib-vlaanderen-zw.png" height="50">
  </div>
</footer>


<!-- Optional JavaScript -->
<!-- jQuery first, then Popper.js, then Bootstrap JS -->
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
  integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"
  integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"
  integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
