<aside [ngClass]="{ 'visible': visible }">
  <div class="menu-header">
    <span (click)="toggleMenu()" class="close-button">&times;</span>
    <div class="content">
      <div class="logo">
        <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
      </div>
      <div class="name">clee</div>
    </div>
    <ra-language-selector></ra-language-selector>
  </div>
  <div class="menu-items">
    <ul>
      <li *ngIf="isLoggedIn">
        <a href
           [routerLink]="['/app/kandidaturen' | localize]"
           [routerLinkActive]="'active'">
          <div class="icon">
            <svg-icon src="/assets/images/bookmark.svg" [applyCss]="true"></svg-icon>
          </div>
          <div class="text">{{ 'Menu.MyCandidacies' | translate }}</div>
        </a>
      </li>
      <li *ngIf="isLoggedIn">
        <a href
           [routerLink]="['/app/profiel' | localize]"
           [routerLinkActive]="'active'">
           <div class="icon">
             <svg-icon src="/assets/images/user-2.svg" [applyCss]="true"></svg-icon>
           </div>
           <div class="text">{{ 'Menu.MyProfile' | translate }}</div>
        </a>
      </li>
      <li>
        <a href
           [routerLink]="['/app/faq' | localize]"
           [routerLinkActive]="'active'">
          <div class="icon">
            <svg-icon src="/assets/images/help-circle.svg" [applyCss]="true"></svg-icon>
          </div>
          <div class="text">{{ 'Menu.Faq' | translate }}</div>
        </a>
      </li>
      <li>
        <a href
           [routerLink]="['/app/contact' | localize]"
           [routerLinkActive]="'active'">
          <div class="icon">
            <svg-icon src="/assets/images/mail.svg" [applyCss]="true"></svg-icon>
          </div>
          <div class="text">{{ 'Menu.Contact' | translate }}</div>
        </a>
      </li>
      <li>
        <a href="{{'Url.Privacy' | translate}}"
          target="_blank"
        >
          <div class="icon">
            <svg-icon src="/assets/images/file-text.svg" [applyCss]="true"></svg-icon>
          </div>
          <div class="text">{{ 'Menu.Privacy' | translate }}</div>
        </a>
      </li>
      <li>
        <a href="{{'Url.UserPolicy' | translate}}"
          target="_blank"
        >
          <div class="icon">
            <svg-icon src="/assets/images/file-text.svg" [applyCss]="true"></svg-icon>
          </div>
          <div class="text">{{ 'Menu.Conditions' | translate }}</div>
        </a>
      </li>
      <li *ngIf="isLoggedIn">
        <a href (click)="logout($event)" class="logout">
          <div class="icon">
          </div>
          <div class="text">{{ 'Menu.LogOut' | translate }}</div>
        </a>
      </li>
      <li *ngIf="!isLoggedIn">
        <a *ngIf="!isLoggedIn" [routerLink]="'/login' | localize">
          <div class="icon">
          </div>
          <div class="text">{{ 'Menu.LogIn' | translate }}</div>
        </a>
      </li>
      <li *ngIf="!isLoggedIn">
        <a [routerLink]="'/registreer' | localize">
          <div class="icon">
          </div>
          <div class="text">{{ 'Menu.Register' | translate }}</div>
        </a>
      </li>
    </ul>
  </div>
  <h3 class="logos-title">{{ 'Footer.WithTheSupportOf' | translate }}</h3>
  <div class="menu-footer">
    <div class="logos">
      <svg-icon src="assets/images/logo_CIB.svg" [applyCss]="true"></svg-icon>
      <svg-icon src="assets/images/logo_Oris.svg" [applyCss]="true"></svg-icon>
      <svg-icon src="assets/images/logo_Vlaanderen.svg" [applyCss]="true"></svg-icon>
    </div>
  </div>
</aside>
