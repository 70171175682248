<div class="footer visible-from-desktop">
  <div class="center-container">
    <div class="text">
      {{ 'Footer.WithTheSupportOf' | translate }}
    </div>
    <div class="logos">
      <div class="logo">
        <svg-icon src="assets/images/logo_CIB.svg" [applyCss]="true"></svg-icon>
      </div>
      <div class="logo">
        <svg-icon src="assets/images/logo_Oris.svg" [applyCss]="true"></svg-icon>
      </div>
      <div class="logo">
        <svg-icon src="assets/images/logo_Vlaanderen.svg" [applyCss]="true"></svg-icon>
      </div>
    </div>
    <div class="app">
      <div class="logo">
        <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
      </div>
      <div class="name">clee</div>
      <div class="copyright">
        © {{ year }}
      </div>
    </div>
  </div>
</div>
