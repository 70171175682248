import { Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';

@Component({
  selector: 'ra-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['../common.scss', './contact-info.component.scss']
})
export class ContactInfoComponent extends StepBaseComponent {
  @HostBinding('class') hostClass = 'wizard';

  previous() {
    this.localizedRouter.navigate(['../persoonlijke-gegevens'], {
      relativeTo: this.route
    });
  }

  onPersonChanged() {
    if (!this.person.address) {
      this.person.address = {};
    }
  }

  next() {
    this.localizedRouter.navigate(['../inkomsten'], { relativeTo: this.route });
  }
}
