<header>
  <div class="menu">

  </div>
  <div class="intro">
      <div class="row">
          <div class="col-12 col-md-8 offset-md-2">
              <a href="" title="home"><img src="/assets/images/Clee-logo-name-white.png" alt="Clee logo" width="200px" /></a>

              <p>De sleutel tot jouw huurwoning</p>
          </div>
      </div>
  </div>
</header>

<div class="container">
<div class="row">
  <div class="col-12 col-lg-6">
      <div class="card menu-card move-up">
          <div class="card-body">
              <div class="row">
                  <div class="col-2 d-none d-md-block iconbox">
                      <i class="fas fa-rocket"></i>
                  </div>
                  <div class="col-12 col-md-10">
                      <h2>Aan de slag</h2>
                      <p>Hieronder vind je de basisdocumentatie om aan de slag te gaan met de Clee widget.</p>

                      <ul class="topics">
                          <li><a href="nl/documentation/aandeslag#step0"><i class="fas fa-file-alt"></i> Algemeen</a></li>
                          <li><a href="nl/documentation/aandeslag#step1"><i class="fas fa-file-alt"></i> Je domein whitelisten in RealSmart</a></li>
                          <li><a href="nl/documentation/aandeslag#step2"><i class="fas fa-file-alt"></i> JSON-LD</a></li>
                          <li><a href="nl/documentation/aandeslag#step3"><i class="fas fa-file-alt"></i> Onze javascript integreren</a></li>
                          <li><a href="nl/documentation/aandeslag#step4"><i class="fas fa-file-alt"></i> Bepaal de locatie van je widget</a></li>
                          <li><a href="nl/documentation/aandeslag#step5"><i class="fas fa-file-alt"></i> Activeer de widget</a></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="col-12 col-lg-6">
      <div class="card menu-card move-up advanced-block">
          <div class="card-body">
              <div class="row">
                  <div class="col-2 d-none d-md-block iconbox">
                      <i class="fas fa-cog"></i>
                  </div>
                  <div class="col-12 col-md-10">
                      <h2>Geavanceerd</h2>
                      <p>Geavanceerde instellingen waarmee je de look & feel van de widget kan aanpassen.</p>

                      <ul class="topics">
                          <li><a href="nl/documentation/aandeslag#step6"><i class="fas fa-file-alt"></i> De look & feel aanpassen</a></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="col-12">
      <div class="card menu-card">
          <div class="card-body">
              <div class="row">
                  <div class="col-3 col-md-1 iconbox d-none d-md-block">
                      <i class="fas fa-question"></i>
                  </div>
                  <div class="col-12 col-md-11">
                      <h2>Hulp nodig?</h2>
                      <p>Vragen of problemen met betrekking tot de integratie van de widget?</p>
                      <a class="btn btn-primary" href="mailto:contact@clee.be">Contacteer ons</a>
                  </div>
              </div>
          </div>
      </div>
  </div>

</div>
</div>

<footer>
<div class="container">
  <img src="/assets/images/oris-logo.png" height="50">
  <img src="/assets/images/cib-vlaanderen-zw.png" height="50">
</div>
</footer>


<!-- Optional JavaScript -->
<!-- jQuery first, then Popper.js, then Bootstrap JS -->
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>