import * as tslib_1 from "tslib";
import { StepBaseComponent } from '../step-base.component';
var ContactInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactInfoComponent, _super);
    function ContactInfoComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hostClass = 'wizard';
        return _this;
    }
    ContactInfoComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../persoonlijke-gegevens'], {
            relativeTo: this.route
        });
    };
    ContactInfoComponent.prototype.onPersonChanged = function () {
        if (!this.person.address) {
            this.person.address = {};
        }
    };
    ContactInfoComponent.prototype.next = function () {
        this.localizedRouter.navigate(['../inkomsten'], { relativeTo: this.route });
    };
    return ContactInfoComponent;
}(StepBaseComponent));
export { ContactInfoComponent };
